import request from '@/utils/request';


/**
 * @description  创建问诊
 * @param {memberId} params
 * afterDiagnosis	AfterDiagnosis{ date:就诊日期,name:患者姓名typeCode:身份类型}
appointment	MakeAnAppointment{date:日期,value:时间段,week:周几}
archivesId:患者档案编号
doctorId:医生编号
image:[]
notes:问题描述
qorderEnum:[zh, yz, dh, jh, xf, dm ]
 * @returns
 */
export function seekCreate(data) {
  return request({
    url: '/seek/create',
    method: 'post',
    data,
  });
}

/**
 * @description 获取订单详情
 * @param {id params
 * @returns
 */
export function getPayOrderDetails(params) {
  return request({
    url: '/seek/getPayOrderDetails',
    method: 'get',
    params,
  });
}

/**
 * @description 用户删除订单
 * @param {id params}
 * @returns
 */
export function deleteOrder(params) {
  return request({
    url: '/seek/deleteOrder',
    method: 'post',
    params,
  });
}

/**
 * @description 患者 / 医生问诊列表
 * @param {status: 0未付款 1 已付款 2已接单 3 完成 4 进行中 5 已关闭 全部传空, keyWord} params
 * @returns
 */
export function getUserQanswersOrderList(params) {
  return request({
    url: '/seek/getUserQanswersOrderList',
    method: 'get',
    params,
  });
}

/**
 * @description 医生获取抢单列表
 * @param { keyWord} params
 * @returns
 */
export function getGrabOrdersList(params) {
  return request({
    url: '/seek/getGrabOrdersList',
    method: 'get',
    params,
  });
}
/**
 * @description 聊天窗口问诊记录
 * 患者查看的时候userId传医生编号，反之医生查看的时候传入患者编号
 * 不分页
 * @param { userId} params
 * @returns
 */
export function getWzLogs(params) {
  return request({
    url: '/seek/getWzLogs',
    method: 'get',
    params,
  });
}
/**
 * @description 获取问诊筛选姓名
 */
export function getWzNickName(params) {
  return request({
    url: '/seek/getWzNickName',
    method: 'get',
    params,
  });
}

// 医生查看预约加号列表
export function getDoctorPlusOrder(params) {
  return request({
    url: '/seek/getDoctorPlusOrder',
    method: 'get',
    params,
  });
}


// 问诊-医生订单列表
export function getDoctorQanswersOrder(params) {
  return request({
    url: '/seek/getDoctorQanswersOrder',
    method: 'get',
    params,
  });
}
/**
 * @description  获取问诊记录
 * @param {orderId: 问诊订单id} params
 * @returns
 */
export function getLogList(params) {
  return request({
    url: '/seek/getLogList',
    method: 'get',
    params,
  });
}

/**
 * @description  医生或者患者查看订单聊天信息
 * @param {orderId: 问诊订单id} params
 * @returns
 */
export function getImDataList(params) {
  return request({
    url: '/seek/getImDataList',
    method: 'get',
    params,
  });
}


/**
 * @description  医生/患者回复订单聊天信息
 * @param orderId: 问诊订单id params
 * @param imTypeEnum： 类型 文字text,图片image
 * @param txt，image
 * @returns
 */
export function doctorReply(data) {
  return request({
    url: '/seek/doctorReply',
    method: 'post',
    data,
  });
}
export function memberQuestioning(data) {
  return request({
    url: '/seek/memberQuestioning',
    method: 'post',
    data,
  });
}

/**
 * @description  医生接诊
 * @param {id: 问诊订单id} params
 * @returns
 */
export function orderReceiving(data) {
  return request({
    url: '/seek/orderReceiving',
    method: 'post',
    data,
  });
}

/**
 * @description  设置此消息不用回复
 * @param {id: 问诊订单id} params
 * @returns
 */
export function setNotReply(data) {
  return request({
    url: '/seek/notReply',
    method: 'post',
    data,
  });
}

/**
 * @description  医生接加号门诊
 * @param {id: 问诊订单id} params
 * @returns
 */
export function plusReceiving(data) {
  return request({
    url: '/seek/plusReceiving',
    method: 'post',
    data,
  });
}


/**
 * @description  医生拒绝接诊
 * @param {"orderId": 0,"other": "string","reason": ["string"]} params
 * @returns
 */
export function noOrderReceiving(data) {
  return request({
    url: '/seek/noOrderReceiving',
    method: 'post',
    data,
  });
}

/**
 * @description  医生门诊标记完成
 * @param {id} params
 * @returns
 */
export function plusSuccess(data) {
  return request({
    url: '/seek/plusSuccess',
    method: 'post',
    data,
  });
}

/**
 * @description  医生抢单
 * @param {orderId: 问诊订单id} params
 * @returns
 */
export function quickQiangDan(data) {
  return request({
    url: '/seek/quickQiangDan',
    method: 'post',
    data,
  });
}
/**
 * @description  医生送追问包
 * @param {orderId: 问诊订单id，num：次数} params
 * @returns
 */
export function doctorSendQuestioning(data) {
  return request({
    url: '/seek/doctorSendQuestioning',
    method: 'post',
    data,
  });
}

/**
 * @description  问诊页面 医生患者获取可操作订单
 * @param {id: 问诊订单id} params
 * @returns
 */
export function getDoctorOrderList(params) {
  return request({
    url: '/seek/getDoctorOrderList',
    method: 'get',
    params,
  });
}
export function getWzPageUserOrderList(params) {
  return request({
    url: '/seek/getWzPageUserOrderList',
    method: 'get',
    params,
  });
}
/**
 * @description  患者取消订单
 * @param {"orderId": 0,} params
 * @returns
 */
export function userCancel(data) {
  return request({
    url: '/seek/userCancel',
    method: 'post',
    data,
  });
}

/**
 * @description  患者提前结束问诊
 * @param {"orderId": 0,} params
 * @returns
 */
export function successConfirm(data) {
  return request({
    url: '/seek/successConfirm',
    method: 'post',
    data,
  });
}

/**
 * @description  创建预约加号
 * @param {memberId} params
 *
appointment	{date:日期,value:时间段,week:周几}
archivesId:患者档案编号
doctorId:医生编号
image:[]
notes:问题描述
 * @returns
 */

export function appointmentPlus(data) {
  return request({
    url: '/seek/appointmentPlus',
    method: 'post',
    data,
  });
}

// 医生撤回消息 imDataId
export function messageRecall(data) {
  return request({
    url: '/newServices/messageRecall',
    method: 'post',
    data,
  });
}

/**
 * @description  患者查看门诊取消
 * @param {nickname,status}
 * @returns
 */
export function memberCancelPlus(data) {
  return request({
    url: '/seek/memberCancelPlus',
    method: 'post',
    data,
  });
}

export function getPlusDataTimeList(params) {
  return request({
    url: '/doctor/getPlusDataTimeList',
    method: 'get',
    params,
  });
}
/**
 * @description  患者查看门诊提交列表
 * @param {nickname,status}
 * @returns
 */
export function getMemberPlusOrder(params) {
  return request({
    url: '/seek/getMemberPlusOrder',
    method: 'get',
    params,
  });
}
/**
 * @param {id} 患者查看自己加号订单详情
 */
export function getPlusOrderDetail(params) {
  return request({
    url: '/seek/getPlusOrderDetail',
    method: 'get',
    params,
  });
}
/**
 * @param {id} 医生查看加号订单详情
 */
export function getDoctorPlusOrderDetail(params) {
  return request({
    url: '/seek/getDoctorPlusOrderDetail',
    method: 'get',
    params,
  });
}


/**
 * @description  医生拒绝接门诊加号
 * @param {"id": 0,"reason": ["string"]} params
 * @returns
 */
export function doctorReturnPlus(data) {
  return request({
    url: '/seek/doctorReturnPlus',
    method: 'post',
    data,
  });
}


/**
 * @description  患者查看自己就诊人列表
 * @returns
 */
export function getPlusNickName(params) {
  return request({
    url: '/seek/getPlusNickName',
    method: 'get',
    params,
  });
}


/**
 * @description  创建快速咨询
 * @param {memberId} params
 *
archivesId:患者档案编号
doctorId:医生编号
image:[]
notes:问题描述
 * @returns
 */
export function quickCreate(data) {
  return request({
    url: '/seek/quickCreate',
    method: 'post',
    data,
  });
}
// 医生撤回消息 departmentId,,money,title
export function fastDoctorCount(params) {
  return request({
    url: '/newServices/fastDoctorCount',
    method: 'get',
    params,
  });
}
