<template>
  <div style="background: white; padding-bottom: 50px; padding-top: 10px">
    <base-list
      :listFinished="listFinished"
      :listLoading="listLoading"
      :reFreshloading="reFreshloading"
      @onRefresh="onRefresh"
      @onLoadList="onLoadList"
      v-if="seekList.length"
      class="padding15"
    >
      <base-box v-for="(item, index) in seekList" :key="index" @click="handleNavigation(item.id)">
        <div class="list-item">
          <div class="member-info">
            <base-avatar v-if="item.userInfo" :src="item.userInfo.cover" height="40"></base-avatar>
            <div class="info" :style="{ marginLeft: item.userInfo ? '15px' : '0' }">
              <div class="name" v-if="item.archives">
                {{ item.archives.fullName }} {{ item.archives.sex }}
                {{ item.archives.age }}
              </div>
              <div class="tip van-ellipsis">{{ item.content }}</div>
            </div>
          </div>
          <van-divider :style="{ margin: '10px 0' }" />
          <div class="order-status">
            <div>{{ item.statusStr }}</div>
            <div class="time">{{ item.lastTime }}</div>
          </div>
        </div>
      </base-box>
    </base-list>
    <van-empty
      description="暂时没有问诊记录"
      :image="require(isDoctor ? '../../assets/img-no-patient-seek.png' : '../../assets/img-no-doctor-seek.png')"
      v-if="!seekList.length && !listLoading"
    >
      <van-button round type="primary" class="linear-blue" to="search" v-if="!isDoctor">找医生</van-button>
    </van-empty>
  </div>
</template>

<script>
import { getDoctorOrderList, getWzPageUserOrderList } from '@/api/seek';
import { reactive, ref } from '@vue/reactivity';
import BaseList from '@/components/BaseList.vue';
import { Button, Divider, Empty } from 'vant';
import store from '@/store';
import BaseBox from '@/components/BaseBox.vue';
import BaseAvatar from '@/components/BaseAvatar.vue';
import { useRouter } from 'vue-router';
import { onActivated } from '@vue/runtime-core';

export default {
  name: 'ask',
  components: {
    BaseList,
    BaseBox,
    BaseAvatar,
    [Divider.name]: Divider,
    [Empty.name]: Empty,
    [Button.name]: Button,
  },
  setup() {
    const params = reactive({
      page: 1,
      limit: 11,
      keyWord: '',
    });

    const seekList = ref([]);
    const listFinished = ref(false);
    const listLoading = ref(true);
    const reFreshloading = ref(false);

    const onLoadList = function () {
      params.page += 1;
      listLoading.value = true;
      getList();
    };
    const onRefresh = function () {
      params.page = 1;
      listFinished.value = false;
      listLoading.value = true;
      reFreshloading.value = true;
      getList();
    };
    const getList = async function () {
      let data = {};
      if (store.getters['user/userInfo'].isDoctor) {
        data = await getDoctorOrderList(params);
      } else {
        data = await getWzPageUserOrderList(params);
      }
      // getUserQanswersOrderList(params).then((res) => {
      if (data.code == 200) {
        if (reFreshloading.value) {
          seekList.value = [];
          reFreshloading.value = false;
        }
        listLoading.value = false;
        listFinished.value = false;
        if (params.page >= data.data.total_page) {
          listFinished.value = true;
        }
        seekList.value = [...seekList.value, ...data.data.data];
      }
      // });
    };

    onActivated(() => {
      params.page = 1;
      listFinished.value = false;
      listLoading.value = true;
      reFreshloading.value = true;
      getList();
    });
    const userInfo = store.getters['user/userInfo'];
    const router = useRouter();
    const handleNavigation = function (id) {
      router.push({
        name: userInfo.isDoctor ? 'doctorImageText' : 'patientImageText',
        params: { id },
      });
    };
    return {
      seekList,
      listFinished,
      listLoading,
      reFreshloading,
      getList,
      onLoadList,
      onRefresh,
      handleNavigation,
      isDoctor: userInfo.isDoctor,
    };
  },
};
</script>
<style lang="scss" scoped>
.list-item {
  .member-info {
    display: flex;
    align-items: center;
    .info {
      color: $black;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .name {
        font-size: $font15;
        font-weight: 600;
        margin-bottom: 13px;
      }
      .tip {
        font-size: $font12;
        color: #a6aeb3;
      }
    }
  }
  .order-status {
    display: flex;
    justify-content: space-between;
    font-size: $font14;
    .time {
      color: #bcc5cb;
    }
  }
}
.padding15 {
  height: calc(100vh - 60px);
  overflow: scroll;
}
</style>
