<template>
  <van-pull-refresh v-model="freshloading" @refresh="onRefresh">
    <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text="没有更多啦"
      @load="onLoadList"
      :immediate-check="false"
      offset="100"
    >
      <div style="height: 1px"></div>

      <slot></slot>
    </van-list>
  </van-pull-refresh>
</template>

<script>
import { List, PullRefresh } from 'vant';
import { ref } from '@vue/reactivity';
import { onUpdated } from '@vue/runtime-core';

export default {
  components: {
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
  },
  props: {
    reFreshloading: Boolean,
    listLoading: Boolean,
    listFinished: Boolean,
  },
  emits: ['onRefresh', 'onLoadList'],
  setup(props, { emit }) {
    const loading = ref(props.listLoading);
    const freshloading = ref(props.reFreshloading);
    const finished = ref(props.listFinished);

    const onRefresh = () => {
      emit('onRefresh');
    };
    const onLoadList = () => {
      emit('onLoadList');
    };

    onUpdated(() => {
      loading.value = props.listLoading;
      freshloading.value = props.reFreshloading;
      finished.value = props.listFinished;
    });

    return { freshloading, loading, finished, onRefresh, onLoadList };
  },
};
</script>
<style lang="scss">
.van-pull-refresh__head {
  z-index: -1;
}
</style>
